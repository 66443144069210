<template>
    <div class="pb-10">
        <div class="bg-custom-100 pt-10 pb-24">
            <div class="max-w-7xl mx-auto px-6 lg:px-8">
                <Header name="Terms of Service" subtitle="Last updated: November 27, 2020" />
            </div>
        </div>

        <div class="py-10 -mt-24">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div class="grid grid-cols-3 gap-4">
                    <div class="col-span-3">
                        <Card :hide-shadow-mobile="true">
                            <div class="px-4 py-5 sm:p-6">
                                <h3 class="font-medium text-gray-900 text-lg leading-6">Overview</h3>
                                <div class="mt-4 text-sm leading-5 text-gray-500">
                                    This website (this “Site”) is operated by BrandAmbassador (“BrandAmbassador”, “we”, “us” and “our”). BrandAmbassador hereby offers this Site, including all information, tools and services available from this Site to each user of the Site (“you”, “your” or “User”) conditioned upon your acceptance of all terms, conditions, policies and notices herein. If you have any questions about these Terms of Service, do not hesitate to send your questions to info@brand-ambassador.com. We are happy to answer them!
                                </div>
                                <div class="mt-4 text-sm leading-5 text-gray-500">
                                    By visiting our Site and/or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service” or “Terms”). These Terms of Service apply to all Users of the Site, including Users who are browsers, vendors, customers, merchants, and/ or contributors of content. Please read these Terms of Service carefully before accessing or using our Site. If you do not agree to all the terms and conditions of these Terms of Service, then you shall not access the Site or use our Service.
                                </div>
                                <div class="mt-4 text-sm leading-5 text-gray-500">
                                    All new features or tools that are added to the current store are subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change, amend or replace any part of these Terms of Service by posting updates and/or changesto the Site in a conspicuous manner. It is your responsibility to check the Site periodically for changes, amendments or updates to these Terms. Your continued use of the Site following the posting of any changes, amendments or updates constitutes acceptance of those changes.
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {defineComponent} from 'vue';

    export default defineComponent({
        name: "TermsOfService",
        setup() {
        }
    })
</script>